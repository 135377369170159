import { LocaleMessage } from 'vue-i18n';
import i18n from '@/i18n';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { CommonUserGroups } from '@/enums/global/CommonUserGroups';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import CMSUser from '@/models/CMSUser';
import UserGroupConnection from '@/models/UserGroupConnection';
import { UserRightsCopierFormFields } from '@/enums/components/CmsShow/UserRightsCopierFormFields';
import { UserRightsCopierValues } from '@/interfaces/components/cms/UserRightsCopierValues';
import UserGroup from '@/models/UserGroup';
import { CopyTypes } from '@/enums/components/CmsShow/CopyTypes';
import { notification } from 'ant-design-vue';

/**
 * Compares the given user ids and shows an error if they are the same
 * @param comparedFieldName - The name of the field that the value is being compared to
 * @param value - The value from the active form item
 * @param valueToCompareTo - The value of the other form item
 * @param callback - The callback necessary for validation provided by the form
 */
export function compareUserValues(
    comparedFieldName: string,
    value: string,
    valueToCompareTo: string | null | undefined,
    callback: (errorMessage?: LocaleMessage) => void,
) {
    if (value && value === valueToCompareTo && comparedFieldName === UserRightsCopierFormFields.FROM_USER) {
        callback(i18n.t('Nije moguće postavljati korisnička prava za istog korisnika'));
    } else {
        callback();
    }
}

/**
 * Compares the given user group ids and shows an error if one is Roltek and other is not
 * @param comparedFieldName - The name of the field that the value is being compared to
 * @param selectedUserGroupId - The value from the active form item
 * @param otherUserGroupId - The value of the other form item
 * @param callback - The callback necessary for validation provided by the form
 */
export function compareUserGroupValues(
    comparedFieldName: string,
    selectedUserGroupId: string,
    otherUserGroupId: string | null | undefined,
    callback: (errorMessage?: LocaleMessage) => void,
) {
    const selectedUserGroupEntity = UserGroupRepository.getById(selectedUserGroupId);
    const otherUserGroupEntity = UserGroupRepository.getById(otherUserGroupId);


    if (selectedUserGroupEntity == null || otherUserGroupEntity == null) {
        callback();
        return;
    }

    const isFromGroupRoltekAndOtherNotRoltek = selectedUserGroupEntity.name === CommonUserGroups.ROLTEK &&
        otherUserGroupEntity.name !== CommonUserGroups.ROLTEK;
    const isOtherGroupRoltekAndFromNotRoltek = otherUserGroupEntity.name === CommonUserGroups.ROLTEK &&
        selectedUserGroupEntity.name !== CommonUserGroups.ROLTEK;
    const isComparedFieldFromUserGroup = comparedFieldName === UserRightsCopierFormFields.FROM_USER_GROUP;

    if (isComparedFieldFromUserGroup && isFromGroupRoltekAndOtherNotRoltek || isOtherGroupRoltekAndFromNotRoltek) {
        callback(i18n.t('Nije moguće kopirati korisnička prava iz Roltek korisničke grupe u bilo koju osim Roltek korisničke grupe'));
        return;
    }

    callback();
}

/**
 * Extracts the user groups from the given user
 * @param userId - The user whose user groups need to be extracted
 * @return An array of all user groups
 */
export function extractUserGroupsFromUser(userId: string | null) {
    if (userId == null) {
        return [];
    }
    return (CMSUserRepository.getById(userId) as CMSUser).userGroupConnections
        .map((userGroupConnection) => userGroupConnection.userGroup);
}

/**
 * Validates and submits the form if no errors are present
 * Based on the copyType, it will either update specific user group or all of them
 * @param error - Errors, if any, from the form
 * @param values - All current values from the form
 * @param copyType - The currently selected copy type
 * @return An empty promise
 */
export async function validateAndSubmitForm(error: Error[], values: UserRightsCopierValues, copyType: CopyTypes) {
    if (error) {
        return Promise.reject();
    }

    if (copyType === CopyTypes.SPECIFIC) {
        await updateSpecificUserGroupConnections(values);
    } else {
        await updateAllUserGroupConnections(values);
    }

    notification.success({
        message: `${i18n.t('Promjene uspješne!')}`,
        description: '',
    });

    return Promise.resolve();

}

/**
 * Updates the specific user group connection based on the values selected in the form
 * @param values - All current values from the form
 * @return An empty promise
 */

export async function updateSpecificUserGroupConnections(values: UserRightsCopierValues) {
    await updateUserGroupConnection(values);
    return Promise.resolve();
}

/**
 * Updates the all user group connections on the given user based on the source user
 * It will NOT add the user to new groups, only update the existing ones
 * @param values - All current values from the form
 * @return An empty promise
 */
export async function updateAllUserGroupConnections(values: UserRightsCopierValues) {
    const fromUserGroups =
        CMSUserRepository.getUsersUserGroups(values.fromUser) as UserGroup[];
    const toUserGroups =
        CMSUserRepository.getUsersUserGroups(values.toUser) as UserGroup[];
    const toUserGroupIds = toUserGroups.map((userGroup) => userGroup.id);


    for await (const fromUserGroup of fromUserGroups) {
        if (!toUserGroupIds.includes(fromUserGroup.id)) {
            continue;
        }
        const toUserGroup = toUserGroups.find((userGroup) => userGroup.id === fromUserGroup.id) as UserGroup;

        await updateUserGroupConnection({
            fromUser: values.fromUser,
            toUser: values.toUser,
            fromUserGroup: fromUserGroup.id,
            toUserGroup: toUserGroup.id,
        });
    }

    return Promise.resolve();
}

/**
 * Retrieves the correct user rights and user group connection and updates them on the backend
 * @param values - All current values from the form
 * @return An empty promise
 */
export async function updateUserGroupConnection(values: UserRightsCopierValues) {
    const fromUserUserGroupConnection = CMSUserRepository.getUsersUserGroupConnection(
        values.fromUser,
        values.fromUserGroup,
    );
    const toUserUserGroupConnection = CMSUserRepository.getUsersUserGroupConnection(values.toUser, values.toUserGroup);
    const fromUserUserRights = (fromUserUserGroupConnection as UserGroupConnection).userRights;
    const toUserUserGroupConnectionId = (toUserUserGroupConnection as UserGroupConnection).id;

    await UserGroupConnection.updateUserRights(toUserUserGroupConnectionId, fromUserUserRights);

    return Promise.resolve();
}
