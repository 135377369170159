
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, ConfigProvider, Divider, Form, Select } from 'ant-design-vue';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import { LocaleMessage } from 'vue-i18n';
import { FormRule } from '@/interfaces/antd/FormRule';
import {
    compareUserGroupValues,
    compareUserValues,
    extractUserGroupsFromUser,
    validateAndSubmitForm,
} from '@/helpers/CmsShow/UserRightsCopierHelper';
import UserGroup from '@/models/UserGroup';
import CMSUser from '@/models/CMSUser';
import { UserRightsCopierFormFields } from '@/enums/components/CmsShow/UserRightsCopierFormFields';
import { UserRightsCopierValues } from '@/interfaces/components/cms/UserRightsCopierValues';
import { CopyTypes } from '@/enums/components/CmsShow/CopyTypes';
import i18n from '@/i18n';

@Component({
    name: 'UserRightsCopierForm',
    components: {Button, Form, FormItem: Form.Item, Select, SelectOption: Select.Option, ConfigProvider, Divider},
})
export default class UserRightsCopierForm extends Vue {
    @Prop({default: () => []}) private users!: CMSUser[];

    private fromUserGroups: UserGroup[] = [];
    private toUserGroups: UserGroup[] = [];
    private copyType = CopyTypes.ALL;
    private userRightsCopierFormFields = UserRightsCopierFormFields;
    private copyTypes = CopyTypes;
    private isLoading = false;
    private decoratorRules = {
        [UserRightsCopierFormFields.FROM_USER]: [
            UserRightsCopierFormFields.FROM_USER,
            {
                initialValue: null,
                rules: [
                    {
                        required: true,
                        message: i18n.t('Ovo polje je obavezno'),
                    },
                ],
            },
        ],
        [UserRightsCopierFormFields.TO_USER]: [
            UserRightsCopierFormFields.TO_USER,
            {
                initialValue: null,
                rules: [
                    {
                        required: true,
                        message: i18n.t('Ovo polje je obavezno'),
                    },
                ],
            },
        ],
        [UserRightsCopierFormFields.TO_USER_GROUP]: [
            UserRightsCopierFormFields.TO_USER_GROUP,
            {
                initialValue: null,
                rules: [{validator: this.compareToAndFromUserGroups}, {
                    required: true,
                    message: i18n.t('Ovo polje je obavezno'),
                },
                ],
            },
        ],
        [UserRightsCopierFormFields.FROM_USER_GROUP]: [
            UserRightsCopierFormFields.FROM_USER_GROUP,
            {
                initialValue: null,
                rules: [{validator: this.compareToAndFromUserGroups}, {
                    required: true,
                    message: i18n.t('Ovo polje je obavezno'),
                },
                ],
            },
        ],
    };

    private get form() {
        return this.$form.createForm(this, {
            name: 'userRightsCopier',
            onValuesChange: this.updateUserGroupSources,
        } as IformCreateOption);
    }

    private handleSubmit(event: Event) {
        event.preventDefault();

        this.form.validateFields(async (error: Error[], values: UserRightsCopierValues) => {
            this.isLoading = true;

            try {
                await validateAndSubmitForm(error, values, this.copyType);
            } finally {
                this.isLoading = false;
            }

            this.$emit('closeModal');
        });

    }

    private async updateFromUserGroups() {
        await this.$nextTick();
        const fromUserId = this.form.getFieldValue(UserRightsCopierFormFields.FROM_USER);

        this.fromUserGroups = extractUserGroupsFromUser(fromUserId);
    }

    private async updateToUserGroups() {
        await this.$nextTick();
        const toUserId = this.form.getFieldValue(UserRightsCopierFormFields.TO_USER);

        this.toUserGroups = extractUserGroupsFromUser(toUserId);
    }

    private updateUserGroupSources(props: any, fields: UserRightsCopierValues) {
        if (fields.fromUser) {
            this.form.setFieldsValue({
                fromUserGroup: null,
            });
            this.updateFromUserGroups();
        }
        if (fields.toUser) {
            this.form.setFieldsValue({
                toUserGroup: null,
            });
            this.updateToUserGroups();
        }
    }

    private compareToAndFromUserGroups(
        rule: FormRule,
        value: string,
        callback: (errorMessage?: LocaleMessage) => void,
    ) {
        const fieldToCompare = rule.field === UserRightsCopierFormFields.FROM_USER_GROUP ?
            UserRightsCopierFormFields.TO_USER_GROUP : UserRightsCopierFormFields.FROM_USER_GROUP;
        const shouldRevalidate = fieldToCompare === UserRightsCopierFormFields.TO_USER_GROUP;
        const valueToCompare = this.form.getFieldValue(fieldToCompare);

        compareUserGroupValues(fieldToCompare, value, valueToCompare, callback);

        if (shouldRevalidate) {
            this.form.validateFields([fieldToCompare], {force: true});
        }
    }
}
