
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { Button, Modal } from 'ant-design-vue';
import CMSUser from '@/models/CMSUser';
import UserRightsCopierForm from '@/helpers/CmsShow/UserRightsCopierForm.vue';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { ModalWidth } from '@/enums/global/ModalWidth';

@Component({
    name: 'UserRightsCopier',
    components: {UserRightsCopierForm, Modal, Button},
})
export default class UserRightsCopier extends mixins<UserRightsMixin>(UserRightsMixin) {
    private isModalVisible = false;
    private modalWidth = ModalWidth;

    private get users() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    private openModal() {
        this.isModalVisible = true;
    }

    private closeModal() {
        this.isModalVisible = false;
    }

    private mounted() {
        CMSUser.getAllForUserRights();
    }
}
