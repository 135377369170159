
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EntityTypes } from '@/enums/models/EntityTypes';
import { EventBus } from '@/helpers/EventBusHelper';
import UserRightsCopier from '@/components/views/CmsShow/UserRightsCopier.vue';
import Vue from 'vue';
import CmsUsers from '@/views/CmsUsers.vue';

export default Vue.extend({
    components: {
        UserRightsCopier,
    },

    methods: {
        onEditChecklistField(item: any) {
            const navigationItem = {
                entityId: item.id,
                additionalEntityId: null,
            };

            if (this.$route.params.entityName === CmsEntityTypes.PdfOfferOptions) {
                navigationItem.additionalEntityId = item.offerPdfProperties_id;
            }

            if (this.$route.params.entityName === CmsEntityTypes.PdfOrderTemplatesOptions) {
                navigationItem.additionalEntityId = item.orderPdfProperties_id;
            }

            this.$emit(EventBusEvents.openEntityIndex, navigationItem);
        },
    },
    props: {
        entityData: Object,
        showArchived: Boolean,
    },
    data() {
        return {
            columns: [
                {
                    title: this.$t('Name'),
                    dataIndex: 'name',
                    scopedSlots: { customRender: 'name' },
                    sortDirections: ['descend', 'ascend'],
                    __this: this,
                    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                    customCell(record: any): object {
                        return {
                            on: {
                                click: async () => {
                                    const navigationItem = {
                                        entityId: record.id,
                                        additionalEntityId: null,
                                    };
                                    this.__this?.$emit(EventBusEvents.openEntityIndex, navigationItem);
                                },
                            },
                        };
                    },
                },
                {
                    title: this.$t('Email'),
                    dataIndex: 'email',
                    scopedSlots: { customRender: 'email' },
                    sortDirections: ['descend', 'ascend'],
                    __this: this,
                    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                    customCell(record: any): object {
                        return {
                            on: {
                                click: async () => {
                                    const navigationItem = {
                                        entityId: record.id,
                                        additionalEntityId: null,
                                    };
                                    this.__this?.$emit(EventBusEvents.openEntityIndex, navigationItem);
                                },
                            },
                        };
                    },
                },
                {
                    title: this.$t('Type'),
                    dataIndex: 'userType',
                    scopedSlots: { customRender: 'userType' },
                    __this: this,
                    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                    customCell(record: any): object {
                        return {
                            on: {
                                click: async () => {
                                    const navigationItem = {
                                        entityId: record.id,
                                        additionalEntityId: null,
                                    };
                                    this.__this?.$emit(EventBusEvents.openEntityIndex, navigationItem);
                                },
                            },
                        };
                    },
                },
                {
                    title: this.$t('Roles'),
                    dataIndex: 'roles',
                    scopedSlots: { customRender: 'roles' },
                    __this: this,
                    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                    customCell(record: any): object {
                        return {
                            on: {
                                click: async () => {
                                    const navigationItem = {
                                        entityId: record.id,
                                        additionalEntityId: null,
                                    };
                                    this.__this?.$emit(EventBusEvents.openEntityIndex, navigationItem);
                                },
                            },
                        };
                    },
                },
            ],
            search: '',
        };
    },

    computed: {
        filteredList(): any {
            const { entityData, search, showArchived } = this;
            return entityData.dataSource.filter((user: any) => {
                return user.name.toLowerCase().includes(search.toLowerCase()) && showArchived === user.isArchived;
            });
        },

        total(): number {
            const { entityData, search, showArchived } = this;
            return entityData.dataSource.filter((user: any) => {
                return user.name.toLowerCase().includes(search.toLowerCase()) && showArchived === user.isArchived;
            }).length;
        },
    },
});
