import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import ProductSystem from '@/models/ProductSystem';
import Label from '@/models/Label';
import CampaignType from '@/models/CampaignType';
import Campaign from '@/models/Campaign';
import NextStepType from '@/models/NextStepType';
import CMSUser from '@/models/CMSUser';
import ClientType from '@/models/ClientType';
import Tax from '@/models/Tax';
import DocumentName from '@/models/DocumentName';
import UserGroup from '@/models/UserGroup';
import OfferConfiguration from '@/models/OfferConfiguration';
import OrderConfiguration from '@/models/OrderConfiguration';
import LeadType from '@/models/LeadType';
import LeadOrigin from '@/models/LeadOrigin';
import Currency from '@/models/Currency';
import OrderStatus from '@/models/OrderStatus';
import PaymentType from '@/models/PaymentType';
import ProductForm from '@/models/ProductForm';
import TaxDetails from '@/models/TaxDetails';
import BankAccount from '@/models/BankAccount';
import ChecklistFieldRepository from '@/repositories/ChecklistFieldRepository';
import { ProductSystemRepository } from '@/repositories/ProductSystemRepository';
import LabelReporistory from '@/repositories/LabelReporistory';
import CampaignTypeRepository from '@/repositories/CampaignTypeRepository';
import CampaignReporistory from '@/repositories/CampaignReporistory';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import TaxRepository from '@/repositories/TaxRepository';
import DocumentNameRepository from '@/repositories/DocumentNameRepository';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import OfferConfigurationRepository from '@/repositories/OfferConfigurationRepository';
import LeadTypeRepository from '@/repositories/LeadTypeRepository';
import BankAccountRepository from '@/repositories/BankAccountRepository';
import LeadOriginRepository from '@/repositories/LeadOriginRepository';
import CurrencyRepository from '@/repositories/CurrencyRepository';
import OrderStatusRepository from '@/repositories/OrderStatusRepository';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import TaxDetailsRepository from '@/repositories/TaxDetailsRepository';
import { CmsShowEntitySettings } from '@/interfaces/components/cms/CmsShowEntitySettings';
import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';
import ProjectLabelTemplateRepository from '@/repositories/ProjectLabelTemplateRepository';
import AutomaticPriceManipulation from '@/models/AutomaticPriceManipulation';
import AutomaticPriceManipulationRepository from '@/repositories/AutomaticPriceManipulationRepository';
import EmailTemplateProperty from '@/models/EmailTemplateProperty';
import EmailTemplatePropertyRepository from '@/repositories/EmailTemplatePropertyRepository';
import { LocalStorageService } from '@/services/LocalStorageService';
import { RawLocation, Route } from 'vue-router/types/router';
import store from '@/store';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { CurrentEntityOptions } from '@/interfaces/components/cms/CurrentEntityOptions';
import { NewRouteConfiguration } from '@/interfaces/components/cms/NewRouteConfiguration';
import { RouteNames } from '@/enums/routes/RouteNames';
import router from '@/router';
import ProductGroup from '@/models/ProductGroup';
import ProductGroupRepository from '@/repositories/ProductGroupRepository';
import OrderConfigurationRepository from '@/repositories/OrderConfigurationRepository';
import FlaggedPdfValue from '@/models/FlaggedPdfValue';
import FlaggedPdfValueRepository from '@/repositories/FlaggedPdfValueRepository';
import WarrantyWarning from '@/models/WarrantyWarning';
import WarrantyWarningRepository from '@/repositories/WarrantyWarningsRepository';
import { ProductRepository } from '@/repositories/ProductRepository';
import ProductSystemWizard from '@/models/ProductSystemWizard';
import ProductSystemWizardRepository from '@/repositories/ProductSystemWizardRepository';
import { AxiosError } from 'axios';

export const entitySettings: { [key: string]: CmsShowEntitySettings } = {
    [CmsEntityTypes.ChecklistFields]: {
        canBeCreated: false,
        fetchEndpoint: ProductForm.getAll.bind(ProductForm),
        options: {
            dataSource: ChecklistFieldRepository.getValidChecklistFields,
            shouldShowImage: true,
            groupByProperties: 'checklistSegment.checklist.productForm.name',
            shouldLabelsBeTranslated: false,
            shouldRefetch: false,
        },
    },
    [CmsEntityTypes.ProductDetails]: {
        canBeCreated: false,
        fetchEndpoint: ProductSystem.getAll.bind(ProductSystem),
        options: {
            dataSource: ProductSystemRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.ProductImage]: {
        canBeCreated: false,
        fetchEndpoint: ProductForm.getAll.bind(ProductForm),
        options: {
            dataSource: ProductRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.Labels]: {
        canBeCreated: true,
        fetchEndpoint: Label.getAll.bind(Label),
        options: {
            dataSource: LabelReporistory.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: true,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.CampaignTypes]: {
        canBeCreated: true,
        fetchEndpoint: CampaignType.getAll.bind(CampaignType),
        options: {
            dataSource: CampaignTypeRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.Campaigns]: {
        canBeCreated: true,
        fetchEndpoint: Campaign.getAll.bind(Campaign),
        options: {
            dataSource: CampaignReporistory.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.NextStepTypes]: {
        canBeCreated: true,
        fetchEndpoint: NextStepType.getAll.bind(NextStepType),
        options: {
            dataSource: NextStepTypeRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.Users]: {
        canBeCreated: true,
        fetchEndpoint: CMSUser.getAllOnlyUsers.bind(CMSUser),
        options: {
            dataSource: CMSUserRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.ClientTypes]: {
        canBeCreated: true,
        fetchEndpoint: ClientType.getAll.bind(ClientType),
        options: {
            dataSource: ClientTypeRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.Taxes]: {
        canBeCreated: true,
        fetchEndpoint: Tax.getAll.bind(Tax),
        options: {
            dataSource: TaxRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.DocumentNames]: {
        canBeCreated: true,
        fetchEndpoint: DocumentName.getAll.bind(DocumentName),
        options: {
            dataSource: DocumentNameRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.UserGroups]: {
        canBeCreated: true,
        fetchEndpoint: UserGroup.getAll.bind(UserGroup),
        options: {
            dataSource: UserGroupRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.PdfOrderTemplatesOptions]: {
        canBeCreated: false,
        fetchEndpoint: OrderConfiguration.getAll.bind(OrderConfiguration),
        options: {
            dataSource: OrderConfigurationRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.PdfOfferOptions]: {
        canBeCreated: true,
        fetchEndpoint: OfferConfiguration.getAll.bind(OfferConfiguration),
        options: {
            dataSource: OfferConfigurationRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.PostCodeConnections]: {
        canBeCreated: false,
        fetchEndpoint: CMSUser.getAll.bind(CMSUser),
        options: {
            dataSource: CMSUserRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.LeadTypes]: {
        canBeCreated: true,
        fetchEndpoint: LeadType.getAll.bind(LeadType),
        options: {
            dataSource: LeadTypeRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.BankAccount]: {
        canBeCreated: true,
        fetchEndpoint: BankAccount.getAll.bind(BankAccount),
        options: {
            dataSource: BankAccountRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.LeadOrigins]: {
        canBeCreated: true,
        fetchEndpoint: LeadOrigin.getAll.bind(LeadOrigin),
        options: {
            dataSource: LeadOriginRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.Currency]: {
        canBeCreated: true,
        fetchEndpoint: Currency.getAll.bind(Currency),
        options: {
            dataSource: CurrencyRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.OrderStatus]: {
        canBeCreated: true,
        fetchEndpoint: OrderStatus.getAll.bind(OrderStatus),
        options: {
            dataSource: OrderStatusRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.PaymentTypes]: {
        canBeCreated: true,
        fetchEndpoint: PaymentType.getAll.bind(PaymentType),
        options: {
            dataSource: PaymentTypeRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.TaxDetails]: {
        canBeCreated: true,
        fetchEndpoint: TaxDetails.getAll.bind(TaxDetails),
        options: {
            dataSource: TaxDetailsRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.ProjectLabelTemplates]: {
        canBeCreated: true,
        fetchEndpoint: ProjectLabelTemplate.getAll.bind(ProjectLabelTemplate),
        options: {
            dataSource: ProjectLabelTemplateRepository.getAll,
            shouldShowImage: false,
            groupByProperties: 'locale.name',
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.AutomaticPriceManipulations]: {
        canBeCreated: true,
        fetchEndpoint: AutomaticPriceManipulation.getAll.bind(AutomaticPriceManipulation),
        options: {
            dataSource: AutomaticPriceManipulationRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.EmailTemplateProperties]: {
        canBeCreated: true,
        fetchEndpoint: EmailTemplateProperty.getAll.bind(EmailTemplateProperty),
        options: {
            dataSource: EmailTemplatePropertyRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.ProductGroups]: {
        canBeCreated: true,
        fetchEndpoint: ProductGroup.getAll.bind(ProductGroup),
        options: {
            dataSource: ProductGroupRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.FlaggedPdfValues]: {
        canBeCreated: true,
        fetchEndpoint: FlaggedPdfValue.getAll.bind(FlaggedPdfValue),
        options: {
            dataSource: FlaggedPdfValueRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.DimensionsWizard]: {
        canBeCreated: true,
        fetchEndpoint: ProductSystemWizard.getAll.bind(ProductSystemWizard),
        options: {
            dataSource: ProductSystemWizardRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
    [CmsEntityTypes.WarrantyWarnings]: {
        canBeCreated: true,
        fetchEndpoint: WarrantyWarning.getAll.bind(WarrantyWarning, { validWarranty: false }),
        options: {
            dataSource: WarrantyWarningRepository.getAll,
            shouldShowImage: false,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: true,
        },
    },
};

/**
 * Fetches the correct entity based on the entity name
 * @param entityName - The name of the entity that is currently active
 * @return - An empty promise :(
 */
export async function fetchEntitiesBasedOnRouteParam(entityName: string) {
    await entitySettings[entityName].fetchEndpoint();
    return Promise.resolve();
}
/**
 * Evaluates the given entity name and returns a boolean whether a new entity of this type can be created
 * @param entityName - The name of the entity that is currently active
 * @param canCreateUser - A boolean whether the user has the right to create users
 * @param isSuperAdmin - A boolean whether the user is a super admin
 * @return - A boolean whether a new entity of this type can be created
 */
export function determineWhetherNewEntityCanBeCreated(
    entityName: string | null,
    canCreateUser: boolean,
    isSuperAdmin: boolean
) {
    if (entityName == null || entitySettings[entityName] == null) {
        return false;
    }

    const entity = entitySettings[entityName];

    if (entityName === CmsEntityTypes.Users) {
        return canCreateUser;
    } else if (entityName === CmsEntityTypes.UserGroups) {
        return isSuperAdmin;
    } else {
        return entity.canBeCreated;
    }
}

/**
 * Fetches the default value for the selected group. Prioritizes the local storage and falls back to an empty string
 * @param entityName - The name of the entity that is currently active
 * @param dropdownOptions - All available dropdown options
 * @return - The default value for the selected group
 */
export function getDefaultValueForSelectedGroup(entityName: CmsEntityTypes | null, dropdownOptions: any[]) {
    if (entityName == null) {
        return '';
    }

    if (LocalStorageService.has(`${entityName}-group`)) {
        return LocalStorageService.get(`${entityName}-group`);
    } else {
        return dropdownOptions[0] == null ? '' : dropdownOptions[0];
    }
}

/**
 * Gets the entity name from route params
 * It will also store the selected entity name in order to prevent unnecessary refetching based on the state of
 * the current entity
 * @param route - The current route
 * @return - The current entity name or error
 */
export async function setRouteEntity(route: Route) {
    const entityName = route.params.entityName as CmsEntityTypes;
    const currentEntityData = generateGivenEntityData(entityName);

    if (currentEntityData && !shouldRefetchEntity(currentEntityData, entityName)) {
        return Promise.resolve(entityName);
    }

    try {
        await fetchEntitiesBasedOnRouteParam(entityName);
        await store.dispatch('temporaryCmsData/setCmsEntitiesUserGroup', entityName);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        throw e;
    }

    return Promise.resolve(entityName);
}

/**
 * Decides whether the current entity should be refeched
 * Depends on the shouldRefetch flag, the length od the data source or if an entry already exists in the store
 * @param currentEntityData - The data of the current entity
 * @param entityName - The name of the current entity
 * @return - A boolean if it should refetch or not
 */
export function shouldRefetchEntity(currentEntityData: CurrentEntityOptions, entityName: CmsEntityTypes | null) {
    if (currentEntityData.shouldRefetch) {
        return true;
    }
    if (currentEntityData.dataSource.length <= 0) {
        return true;
    }
    return (
        store.getters['temporaryCmsData/cmsEntityUserGroup'](entityName) !== store.getters['jwtData/currentUserGroup']
    );
}

/**
 * Fetches the CMS settings for the given name
 * @param entityName - The name of the current entity
 * @return - The settings for the given name or the default object if the entity name is null
 */
export function generateGivenEntityData(entityName: CmsEntityTypes | null) {
    if (entityName == null || entitySettings[entityName] == null) {
        return {
            dataSource: [],
            shouldShowImage: true,
            groupByProperties: null,
            shouldLabelsBeTranslated: false,
            shouldRefetch: false,
        };
    }

    const entityOptions = entitySettings[entityName].options;

    return {
        ...entityOptions,
        dataSource: entityOptions.dataSource(),
    };
}

/**
 * Redirects to the specific index of a CMS entity
 * Adds the additional entity ID to the queries of the URL
 * @param newRouteConfiguration - The entity ID and the additional entity ID if it exists
 * @param entityName - The name of the current entity
 * @return - An empty promise
 */
export async function openEntityIndex(newRouteConfiguration: NewRouteConfiguration, entityName: CmsEntityTypes | null) {
    if (entityName == null) {
        return;
    }
    const routeOptions: RawLocation = {
        name: RouteNames.cmsIndex,
        params: {
            entityName,
            entityId: newRouteConfiguration.entityId,
        },
    };

    if (newRouteConfiguration.additionalEntityId != null) {
        routeOptions.query = {
            additionalId: newRouteConfiguration.additionalEntityId,
        };
    }
    await router.push(routeOptions);

    return Promise.resolve();
}
